import React from "react"
import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Connecticut Truck Accident Attorney - Bartlett & Grippe",
    description:
      "If you're a truck accident victim seeking compensation, our skilled attorneys use proactive strategies for medical bills, lost income, and pain and suffering. Get help now.",
    heroH1: "Truck Accident Attorney",
    heroH2:
      "Bartlett & Grippe will work to make you whole again after your accident with a semi-truck.",
    heroImage: "truck-accident-attorney.jpg",
    heroImageAlt: "Photograph of a big rig truck",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">
              Bartlett & Grippe, LLC fights for truck accident victims all
              across the country
            </h2>
            <p className="mb-8">
              Because trucks are so much bigger and heavier than cars, accidents
              involving these large commercial carriers are more likely to cause
              debilitating injuries and death to motorists. At Bartlett & Grippe
              LLC, we represent truck accident victims in Connecticut and all
              throughout the United States. Though no amount of money can erase
              the heartache resulting from your injuries, our skilled attorneys
              can advocate for your right to receive just compensation from the
              parties responsible. We employ assertive strategies to obtain
              damages for past and future medical bills, lost income, emotional
              distress and pain and suffering. If you have endured the
              devastating loss of a family member in a trucking accident, we can
              file a <Link to="/ct-wrongful-death/">wrongful death suit</Link>{" "}
              on your behalf.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              We will go after negligent truck drivers and companies on your
              behalf
            </h2>
            <p className="mb-8">
              Truck accidents are tragic events that might well be avoided if
              truckers and the companies that employ them followed the federal
              regulations designed to improve truck safety. The attorneys at
              Bartlett & Grippe LLC can help you pursue justice and compensation
              when you are injured in an accident with a tractor-trailer,
              18-wheeler, or other large truck caused by:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8 space-y-2">
              <li>Driver fatigue</li>
              <li>Aggressive driving behavior</li>
              <li>DUI/DWI and any alcohol related factors</li>
              <li>
                Unsafe driving conditions, such as snow, ice, rain, or fog
              </li>
              <li>Unlicensed, untrained, or under-trained drivers</li>
              <li>Truck rollovers or jackknifes</li>
              <li>Oversized trucks</li>
              <li>Overloaded trucks</li>
              <li>Improperly loaded cargo</li>
              <li>Tire blowouts</li>
              <li>Brake failure</li>
              <li>Poor truck maintenance</li>
              <li>Mechanical defects</li>
            </ul>
            <p className="mb-8">
              The attorneys at Bartlett & Grippe LLC represent individuals
              injured in all types of commercial vehicle accidents. We employ
              diligent negotiation techniques and tenacious courtroom strategies
              to pursue the best possible outcome.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Our proactive attorneys work hard to build strong cases
            </h2>
            <p className="mb-8">
              Trucking companies quickly descend upon the scene of an accident
              to collect valuable evidence and figure out how to reduce their
              responsibility. When officials clear the debris, vital evidence
              disappears, and so may your opportunity to receive the full amount
              of compensation you deserve. Bartlett & Grippe LLC immediately
              jumps into action to protect your interests. During our aggressive
              investigation, we try to preserve crucial evidence, take witness
              statements and photograph the scene to record skid marks and
              vehicle positioning. We understand the anguish that follows a
              catastrophic trucking accident, and we refuse to allow a negligent
              company to take advantage of the surrounding chaos to mitigate its
              liability.
            </p>
            <p className="mb-8">
              When preparing for negotiations or trial, our attorneys consult
              qualified experts who understand the extraordinary impact of
              trucking accidents. In court, these professionals — such as trauma
              doctors, chiropractors, accident reconstruction analysts, and
              mechanics — can offer compelling testimony about your accident and
              resulting injuries.
            </p>
            <p className="mb-8">
              No matter what kind of injury you’ve suffered, Bartlett & Grippe
              LLC stands strong in the face of tough opposition, using all
              available means to present a strong case on your behalf.
            </p>
            <p className="mb-8">
              Truck accidents often cause devastating injuries to the occupants
              of much smaller vehicles. If you have been injured in a trucking
              accident, or if your loved one was killed in one, we offer the
              diligent representation you need, fighting for the compensation
              you deserve.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
